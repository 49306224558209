import { useState, useEffect } from 'react'

const checkForSessionStorage = () => {
  let isPrompted = sessionStorage.getItem('bannerPrompted')
  let isClosed = sessionStorage.getItem('bannerClosed')
  isPrompted = isPrompted && JSON.parse(isPrompted)
  isClosed = isClosed && JSON.parse(isClosed)
  if (!isPrompted && 'sessionStorage' in window) {
    sessionStorage.setItem('bannerPrompted', 'true')
  }

  return { isPrompted, isClosed }
}

const useSessionPrompt = () => {
  const [isPrompted, setIsPrompted] = useState({})
  useEffect(() => {
    setIsPrompted(checkForSessionStorage())
    return () => console.log('CLEANUP BANNER PROMPT')
  }, [])
  return isPrompted
}

export { useSessionPrompt }
