import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'

import sudIc from '../../images/homeMenu/sud_ic.png'
import startedIc from '../../images/homeMenu/started_ic.png'
import stayingIc from '../../images/homeMenu/staying_ic.png'
import treatmentIc from '../../images/homeMenu/treatment_ic.png'
import resourcesIc from '../../images/homeMenu/resources_ic.png'
import providerIc from '../../images/homeMenu/provider_ic2.png'

import CtaCallNumbers from '../CtaCallNumbers'
import CtaLogosGov from '../CtaLogosGov'
import LangCta from '../LangCta'
import { useStyles } from './styles'

const MenuItems = () => {
  const classes = useStyles()

  return (
    <Grid>
      <a  className="skip-main" href="#main" role="none">Skip to main content</a>
      <div className={classes.body}>
      <div className={classes.pushcontent}>
      <h1 className="hiden-title" role="heading" aria-level="1">Recover LA - Main Page</h1>
        <Grid className={classes.boxMenuContainer}>
          <Button className={classes.item}>
            <a href="/sud-101/" className={classes.link}   role="none">
              <img
                role="none"
                alt="Open Page SUD 101"
                className={classes.imageContainer}
                src={sudIc}
              />
              <Typography className={classes.itemTitle}>SUD 101</Typography>
            </a>
          </Button>
          <Button className={classes.item}>
            <a href="/getting-started/" className={classes.link}  role="none">
              <img
                role="none"
                alt="Open Page getting-started"
                className={classes.imageContainer}
                src={startedIc}
              />
              <Typography className={classes.itemTitle}>
                Getting Started
              </Typography>
            </a>
          </Button>
        </Grid>

        <Grid className={classes.boxMenuContainer}>
          <Button className={classes.item}>
            <a href="/staying-safe/" className={classes.link}  role="none">
              <img
                role="none"
                alt="Open Page Staying Safe"
                className={classes.imageContainer}
                src={stayingIc}
              />
              <Typography className={classes.itemTitle}>Staying Safe</Typography>
            </a>
          </Button>
          <Button className={classes.item}>
            <a href="/getting-treatment/" className={classes.link}  role="none">
              <img
                role="none"
                alt="Open Page Getting Treatment"
                className={classes.imageContainer}
                src={treatmentIc}
              />
              <Typography className={classes.itemTitle}>
                Getting Treatment
              </Typography>
            </a>
          </Button>
        </Grid>

        <Grid className={classes.boxMenuContainer}>
        <Button className={classes.item} >
          <a href="/resources/" className={classes.link}  role="none">
            <img
              role="none"
              alt="Open Page Resources"
              className={classes.imageContainer}
              src={resourcesIc}
            />
            <Typography className={classes.itemTitle}>Resources</Typography>
          </a>
        </Button>
        <Button className={classes.item}>
          <a href="/agencies" className={classes.link}  role="none">
            <img
              role="none"
              alt="Open Page Providers"
              className={classes.imageContainer}
              src={providerIc}
            />
            <Typography className={classes.itemTitle}>
              Find a Provider
            </Typography>
          </a>
        </Button>
      </Grid>
    
      </div>


      <LangCta />
      <CtaCallNumbers />
      <CtaLogosGov />
      </div>
      
      
    </Grid>
  )
}

export { MenuItems }
