import { makeStyles } from '@material-ui/core/styles'

import {colors} from '../../theme'

export const useStyles = makeStyles(() => ({
  buttonText: {
    color: colors.textBlue,
    fontFamily: 'Mont-Heavy',
    textTransform: 'none'
  },
  ctaBox: {
    height: 40,
    resizeMode: 'contain',
  },
  ctaCall: {
    alignItems: 'center',
    backgroundColor: colors.brandOrange,
    borderRadius: '5px',
    display: 'flex',
    fontFamily: 'Mont-Heavy',
    height: 40,
    justifyContent: 'center',
    width: 124,
    '&:hover': {
      backgroundColor: colors.brandOrange,
      opacity: 1,
    }
  },
  mainViewCta: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 10,
    padding: '10px 16px',
  },
}))
