import { makeStyles } from '@material-ui/core/styles'

import { colors } from '../../theme'

export const useStyles = makeStyles(() => ({
  body: {
    paddingTop: '1rem',
    backgroundColor: colors.backgroundGrey,
  },
  pushcontent: {
    minHeight: 'calc(100vh - 260px)',
  },
  boxMenuContainer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  imageContainer: {
    width: '40%',
  },
  item: {
    alignItems: 'center',
    backgroundColor: colors.white,
    borderRadius: '8px',
    boxShadow: '0 3px 10px 0 rgba(25,65,117,0.10)',
    color: '#0232ab',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 10,
    textDecoration: 'none',
    textTransform: 'none',
    width: '50%',
  },
  itemTitle: {
    color: colors.brandBlue,
    fontFamily: 'Mont-Bold',
    fontSize: '0.9rem',
    marginTop: 12,
    textAlign: 'center',
    textDecoration: 'none',
  },
  link: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    paddingBottom: 10,
    paddingTop: 14,
    '&:hover': {
      textDecoration: 'none!important',
    }
  },
}))
