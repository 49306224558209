import { makeStyles } from '@material-ui/core/styles'

import { colors } from '../../theme'

export const useStyles = makeStyles(() => ({
  bannerText: {
    color: colors.textBlue,
    display: 'flex',
    flex: 1,
    fontFamily: 'Mont-Bold',
    fontSize: '0.75rem',
    margin: 0,
    textAlign: 'left',
  },
  close: {
    color: colors.textBlue,
  },
  image: {
    color: 'white',
    height: '1.3rem',
    margin: '0 5px',
  },
  logo: {
    color: 'white',
    height: '6vh',
    margin: '0 0 0 6px',
  },
  root: {
    alignItems: 'center',
    backgroundColor: '#ff8500',
    borderRadius: '5px',
    boxShadow: '0 3px 10px 0 rgba(25,65,117,.1)',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 80,
    maxWidth: '420px',
    padding: '12px 20px',
    width: '96vw',
    zIndex: 9999,
  },
  text: {
    flexDirection: 'column',
    margin: '0 10px',
  },
}))
