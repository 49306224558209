import { makeStyles } from '@material-ui/core/styles'

import { colors } from '../../theme'

export const useStyles = makeStyles(() => ({
  bannerText: {
    color: colors.white,
    fontFamily: 'Mont-Bold',
    fontSize: '0.75rem',
    margin: '0 10px',
  },
  close: {
    color: colors.white,
  },
  image: {
    color: 'white',
    height: '1.3rem',
    margin: '0 5px',
  },
  logo: {
    color: 'white',
    height: '4vh',
    margin: '0 6px',
  },  
  root: {
    alignItems: 'center',
    backgroundColor: colors.brandBlue,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    maxWidth: '420px',
    padding: '12px 6px',
    width: '96vw',
  },
  text: {
    flexDirection: 'column',
    margin: '0 10px',
  }
}))
