import {
  Snackbar,
  IconButton,
  Slide,
  Typography,
  Grid,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import React, { useEffect, useState } from 'react'

import { useStyles } from './styles'

import RecoverLaIcon from '../../images/recover_la_app_icon.png'
import IosShare from '../../images/ios_share.png'

import useIsIOS from '../../hooks/useIsIOS'
import useSessionPrompt from '../../hooks/useSessionPrompt'

const TransitionUp = (props) => {
  return <Slide {...props} direction="up" />
}

const InstallBanner = (isOpen) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const { isIOS } = useIsIOS()
  const { isPrompted, isClosed } = useSessionPrompt()

  useEffect(() => {
    setOpen(isOpen)
  }, [])

  // const handleClick = () => {
  //   setOpen(true)
  // }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionUp}
      ClickAwayListenerProps={{
        disableReactTree: true,
        mouseEvent: false,
        touchEvent: false,
      }}
      ContentProps={{
        classes: {
          root: classes.root,
        },
      }}
      style={
        isIOS && (!isPrompted || !isClosed)
          ? { marginBottom: 160 }
          : { marginBottom: 80 }
      }
      children={
        <Grid className={classes.root}>
          <img
            role="none"
            alt="recoverla icon"
            src={RecoverLaIcon}
            className={classes.logo}
          />
          <Grid className={classes.text}>
            <Typography className={classes.bannerText}>
              Install this webapp on your phone:
            </Typography>
            <Typography className={classes.bannerText}>
              Tap
              <img
                role="none"
                alt="ios share icon"
                src={IosShare}
                className={classes.image}
              />
              and then Add to Homescreen
            </Typography>
          </Grid>
          <IconButton
            size="small"
            aria-label="close"
            onClick={handleClose}
            className={classes.close}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
      }
    />
  )
}

export { InstallBanner }
