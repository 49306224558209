import { Grid } from '@material-ui/core'
import React from 'react'

import SashFlat from '../../images/sashFlat1ColorDphBluePms281.png'
import ButtonComponentTwoLines from '../ButtonComponentTwoLine'

import { useStyles } from './styles'

const CtaCallNumbers = () => {
  const classes = useStyles()

  return (
    <Grid className={classes.mainViewCta}>
      <Grid>
        <img
          role="none"
          alt="Substance Abuse Service Helpline 1.844.804.7500"
          className={classes.ctaBox}
          src={SashFlat}
        />
      </Grid>
      <ButtonComponentTwoLines
        url={`1-844-804-7500`}
        text="CALL"
        type="orange"
        callToAction="phone"
        onClick={() =>
          window.gtag('event', 'click', {
            helpline_phone: '1-844-804-7500',
          })
        }
      />
    </Grid>
  )
}

export { CtaCallNumbers }
