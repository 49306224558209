import {
  Snackbar,
  IconButton,
  Slide,
  Typography,
  Grid,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import React, { useEffect, useState } from 'react'

import WhiteIcon from '../../images/white_icon.png'

import { useStyles } from './styles'

const TransitionUp = (props) => {
  return <Slide {...props} direction="up" />
}

const ModalHome = (isOpen) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(isOpen)
  }, [])

  const handleClose = () => {
    sessionStorage.setItem('bannerClosed', 'true')
    setOpen(false)
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      onClose={handleClose}
      TransitionComponent={TransitionUp}
      ClickAwayListenerProps={{
        disableReactTree: true,
        mouseEvent: false,
        touchEvent: false,
      }}
      ContentProps={{
        classes: {
          root: classes.root,
        },
      }}
      children={
        <Grid className={classes.root}>
          <img
            role="none"
            alt="recoverla icon"
            src={WhiteIcon}
            className={classes.logo}
          />
          <Grid className={classes.text}>
            <a
              href="/staying_safe/how-to-recognize-and-respond-to-an-opioid-overdose/"
              className={classes.link}
              role="none"
              onClick={handleClose}
            >
              <Typography className={classes.bannerText}>
                For Fentanyl information
              </Typography>
              <Typography className={classes.bannerText}>Click Here</Typography>
            </a>
          </Grid>
          <IconButton
            size="small"
            aria-label="close"
            onClick={handleClose}
            className={classes.close}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Grid>
      }
    />
  )
}

export { ModalHome }
