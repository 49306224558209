import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() => ({ 
  footerLang: {
    backgroundColor: '#F6F7F9',
    bottom: 0,
    color: '#000',
    position: 'inherit',
    width: '100%',
  },
  mainViewCta: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    paddingBottom: 'calc(1.45rem - 1px)',
    padding: '0 16px',
  },
  langButton: {
    color: '#000000',
  },
  black: {
    color: '#000000',
  }
}))
