import React from 'react'

import InstallBanner from '../components/InstallBanner'
import ModalHome from '../components/ModalHome'
import Layout from '../components/Layout'
import MenuItems from '../components/MenuItems'
import Seo from '../components/Seo'
import useIsIOS from '../hooks/useIsIOS'
import useSessionPrompt from '../hooks/useSessionPrompt'

const IndexPage = () => {
  const { prompt } = useIsIOS()
  const { isPrompted, isClosed } = useSessionPrompt()

  return (
    <Layout>
      <Seo title="Home" />
      <MenuItems />
      {prompt && <InstallBanner isOpen={prompt} />}
      {(!isPrompted || !isClosed) && <ModalHome />}
    </Layout>
  )
}

export default IndexPage
