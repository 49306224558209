import { useState, useEffect } from 'react'
import moment from 'moment'

const checkForIOS = () => {
  if (navigator.standalone) {
    return false
  }

  const today = moment().toDate()
  // const lastPrompt = moment(localStorage.getItem('installPrompt'))
  // const days = moment(today).diff(lastPrompt, 'days')
  const ua = window.navigator.userAgent
  const webkit = !!ua.match(/WebKit/i)
  const isIPad = !!ua.match(/iPad/i)
  const isIPhone = !!ua.match(/iPhone/i)
  const isIOS = isIPad || isIPhone
  const isSafari = isIOS && webkit && !ua.match(/CriOS/i)
  const isInStandaloneMode = window.navigator.standalone;
  const prompt = isIOS && isSafari && !isInStandaloneMode
  // const prompt = (isNaN(days) || days > 3) && isIOS && isSafari && !isInStandaloneMode

  if (prompt && 'localStorage' in window) {
    localStorage.setItem('installPrompt', today)
  }

  return { isIOS, isSafari, prompt }
}

const useIsIOS = () => {
  const [isIOS, setIsIOS] = useState({})
  useEffect(() => {
    setIsIOS(checkForIOS())
    return () => console.log('CLEANUP INSTALL PROMPT', isIOS)
  }, [])
  return isIOS
}

export { useIsIOS }
